import {Box, Stack, Typography} from '@mui/material'
import {Order, OrderType} from 'src/entities/search/general/Order'
import {Person} from 'src/entities/search/general/Person'
import PersonIcon from '@mui/icons-material/Person'
import {formatDate} from 'src/utils/formatTime'
import Label from 'src/components/Label'
import {DataGridPro, GridCellParams, useGridApiRef} from '@mui/x-data-grid-pro'
import {useEffect} from 'react'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'

interface TableProps {
  orders: Order[]
  isGeneral?: boolean
  generalTextSearch?: string
}

const getHighlightedText = (value?: string, generalTextSearch?: string) => {
  if (value && generalTextSearch) {
    const parts = value.split(new RegExp(`(${generalTextSearch})`, 'gi'))
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === generalTextSearch.toLowerCase()
                ? {fontWeight: 'bold', backgroundColor: '#D6E4FF'}
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    )
  } else {
    return value
  }
}

const generateColumns = (generalTextSearch?: string, isGeneral?: boolean) => [
  {
    field: 'orderNumber',
    headerName: 'Order #',
    flex: isGeneral ? 0.4 : 0.3,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      getHighlightedText(params.row.orderNumber, generalTextSearch)
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: isGeneral ? 0.7 : 0.5,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <CuiOverflowTooltip
        value={getHighlightedText(
          [params.row.address, params.row.city, params.row.state]
            .join(' ')
            .trim(),
          generalTextSearch
        )}
      />
    )
  },
  {
    field: 'county',
    headerName: 'County',
    flex: isGeneral ? 0.3 : 0.2,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      getHighlightedText(params.row.county, generalTextSearch)
  },
  {
    field: 'block',
    headerName: 'Block',
    flex: isGeneral ? 0.3 : 0.2,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      getHighlightedText(params.row.block, generalTextSearch)
  },
  {
    field: 'lot',
    headerName: 'Lot',
    flex: isGeneral ? 0.4 : 0.3,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      getHighlightedText(params.row.lot, generalTextSearch)
  },
  {
    field: 'closeDate',
    headerName: 'Close Date',
    flex: isGeneral ? 0.25 : 0.3,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      params.value
        ? getHighlightedText(
            formatDate(params.row.closeDate),
            generalTextSearch
          )
        : '---'
  },
  {
    field: 'openDate',
    headerName: 'Sale Date',
    flex: isGeneral ? 0.25 : 0.3,
    sortable: false,
    renderCell: (params: GridCellParams) =>
      params.value
        ? getHighlightedText(formatDate(params.row.openDate), generalTextSearch)
        : '---'
  },
  {
    field: 'buyerAndSeller',
    headerName: 'Buyer & Seller',
    flex: isGeneral ? 0.9 : 1,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Stack>
        <Stack>
          <Stack spacing={1} direction="row">
            <Typography color="grey.600" variant="body2">
              B
            </Typography>
            {params.row.buyers &&
              params.row.buyers.map((b: Person, key: number) => (
                <Stack spacing={0.2} key={key} direction="row">
                  <PersonIcon sx={{color: 'grey.600'}} />
                  <Typography variant="body2">
                    {getHighlightedText(b.lastName, generalTextSearch)}
                  </Typography>
                  <Typography variant="body2">
                    {getHighlightedText(b.firstName, generalTextSearch)}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
        <Stack>
          <Stack spacing={1} direction="row">
            <Typography color="grey.600" variant="body2">
              S
            </Typography>
            {params.row.sellers &&
              params.row.sellers.map((b: Person, key: number) => (
                <Stack spacing={0.2} key={key} direction="row">
                  <PersonIcon sx={{color: 'grey.600'}} />
                  <Typography variant="body2">
                    {' '}
                    {getHighlightedText(b.lastName, generalTextSearch)}
                  </Typography>
                  <Typography variant="body2">
                    {' '}
                    {getHighlightedText(b.firstName, generalTextSearch)}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
    )
  }
]

export default function Table({
  orders,
  generalTextSearch,
  isGeneral
}: TableProps) {
  const apiRef = useGridApiRef()

  useEffect(() => {
    if (!isGeneral) {
      apiRef.current.updateColumns([
        {
          field: 'type',
          headerName: 'Source',
          flex: 0.3,
          sortable: false,
          renderCell: (params: GridCellParams) => (
            <Label
              textAlign="center"
              fontSize="meduim"
              variant="ghost"
              color={
                params.row.type === OrderType.TitleWave
                  ? 'primary'
                  : 'secondary'
              }
              margin={6}
            >
              {params.row.type === OrderType.TitleWave
                ? 'TitleWave'
                : 'Momentum'}
            </Label>
          )
        }
      ])
    }
  })

  return (
    <Box sx={{width: '100%'}}>
      <DataGridPro
        apiRef={apiRef}
        autoHeight
        rows={orders}
        columns={generateColumns(generalTextSearch, isGeneral)}
        checkboxSelection={false}
        hideFooter={true}
        rowHeight={70}
        pageSize={25}
        getRowId={(row: Order) => row.rootId}
        sx={{
          '& .MuiCheckbox-root.Mui-disabled': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none',
            backgroundColor: 'grey.200'
          },
          '& .MuiDataGrid-cell': {
            border: 'none',
            marginBottom: 6,
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeader:focus-within, .css-up4904-MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none'
            },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none'
          }
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        onCellClick={e => {
          e.row.type === OrderType.TitleWave
            ? window.open(
                `https://ots.madisontitle.com/documents?id=${e.row.rootId}`,
                '_blank'
              )
            : window.open(
                `https://internal.mcres.com/new-home/${e.row.orderNumber}`,
                '_blank'
              )
        }}
      />
    </Box>
  )
}
