// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import {AuthContextProvider} from 'src/contexts/Auth'
import config from 'src/config'
import {LicenseInfo} from '@mui/x-data-grid-pro'

// ----------------------------------------------------------------------
LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)
export default function App() {
  return (
    <AuthContextProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </MotionLazyContainer>
    </AuthContextProvider>
  )
}
