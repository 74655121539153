import React, {useEffect, useState} from 'react'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Skeleton,
  Snackbar,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import {Building} from 'src/entities/search/backtitle/stateCapital/Building'
import {Assessment} from 'src/entities/search/backtitle/stateCapital/Assessment'
import HomeIcon from '@mui/icons-material/Home'
import CuiTitleContent from 'src/components/custom/CuiTitleContent'
import TimeLine from 'src/components/search/results/backtitle/stateCapital/TimeLine'
import {getFullYear} from 'src/utils/formatTime'
import Table from 'src/components/search/results/backtitle/stateCapital/Table'
import CountyLinks from 'src/components/search/CountyLinks.json'
import useResponsive from 'src/hooks/useResponsive'

interface StateCapitalResultsProps {
  buildings: Building[]
  assessments: Map<string, Assessment[]>
  loading: boolean
  isSearch: boolean
}

export default function StateCapitalResults({
  buildings,
  assessments,
  loading,
  isSearch
}: StateCapitalResultsProps) {
  const [selectedBuilding, setSelectedBuilding] = useState<Building>(
    buildings[0]
  )

  const [selectedQualifier, setSelectedQualifier] = useState<string | null>('')
  const [qualifierList, setQualifierList] = useState<string[]>([])
  const [index, setIndex] = useState<number>(0)
  const [isDisplayCard, setIsDisplayCard] = useState<boolean>(false)
  const [assessmentsList, setAssessmentsList] = useState<Assessment[] | null>(
    null
  )
  const [isDisplayDolarDeed, setIsDisplayDolarDeed] = useState<boolean>(true)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const isLaptop = useResponsive('down', 'lg')

  const displayList = isDisplayDolarDeed
    ? assessmentsList
    : assessmentsList?.filter(
        s => !['$1.00', '$10.00', '$100.00'].includes(s.salePrice)
      )

  const onSelectBuilding = (
    _event: React.MouseEvent<HTMLElement>,
    newBuilding: Building
  ) => {
    if (newBuilding !== null) {
      setIsDisplayCard(false)
      setSelectedBuilding(newBuilding)
    }
    setIndex(0)
  }

  const onSelectQalifier = (
    _event: React.MouseEvent<HTMLElement>,
    newQalifier: string | null
  ) => {
    setSelectedQualifier(newQalifier)
    setIndex(
      selectedBuilding.properties.findIndex(i => i.qualifier === newQalifier)
    )
    setIsDisplayCard(newQalifier !== null)
  }

  useEffect(() => {
    if (selectedBuilding) {
      const list = selectedBuilding.properties
        .filter(p => p.qualifier !== '')
        .map(q => q.qualifier)
      setQualifierList(list)
      setSelectedQualifier(list[0])
    } else {
      setQualifierList([])
      setSelectedQualifier('')
    }
    setIsDisplayCard(true)
  }, [buildings, selectedBuilding])

  useEffect(() => {
    if (selectedBuilding && index >= 0) {
      const buildingKey = buildings.findIndex(b => b === selectedBuilding)
      const list = assessments
        .get(buildingKey + '_' + index)
        ?.filter(
          (v, i, a) =>
            a.findIndex(
              v2 => v2.saleDate !== null && v2.saleDate === v.saleDate
            ) === i
        )
      list?.sort((a, b) => getFullYear(a.saleDate) - getFullYear(b.saleDate))
      setAssessmentsList(list || null)
    } else {
      setAssessmentsList(null)
    }
  }, [buildings, selectedBuilding, index, assessments])

  useEffect(() => {
    setSelectedBuilding(buildings[0])
  }, [buildings])

  return (
    <>
      {isSearch && (
        <Card sx={{mt: 3, p: 2}}>
          {!loading && buildings.length > 0 ? (
            <>
              <Typography ml={2} variant="overline">
                STATE CAPITAL ({buildings.length})
              </Typography>
              <Stack
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                width="100%"
              >
                <Stack
                  sx={{
                    overflowX: 'auto',
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                      backgroundColor: 'white',
                      height: 6,
                      paddingBottom: 8
                    },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                      {
                        borderRadius: 1.5,
                        backgroundColor: 'grey.300',
                        paddingBottom: 2
                      }
                  }}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid
                    alignContent="center"
                    flexGrow={1.5}
                    paddingBottom={1}
                    paddingTop={1}
                    container
                    direction="row"
                    alignItems="center"
                    sx={{
                      overflowX: 'auto',
                      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: 'white',
                        height: 6,
                        paddingBottom: 8
                      },
                      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                        {
                          borderRadius: 1.5,
                          backgroundColor: 'grey.300',
                          paddingBottom: 2
                        }
                    }}
                  >
                    <ToggleButtonGroup
                      exclusive
                      sx={{
                        border: 'none'
                      }}
                      value={selectedBuilding}
                      onChange={onSelectBuilding}
                      color="primary"
                      size="small"
                    >
                      {buildings.map((p, k) => {
                        return (
                          <ToggleButton
                            sx={{whiteSpace: 'nowrap'}}
                            key={k}
                            value={p}
                          >
                            {p.municipality}
                          </ToggleButton>
                        )
                      })}
                    </ToggleButtonGroup>
                  </Grid>
                  <Stack>
                    {qualifierList.length > 0 && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={160}
                      >
                        <Autocomplete
                          size="small"
                          onChange={(event: any, newValue: string | null) => {
                            onSelectQalifier(event, newValue)
                          }}
                          defaultValue={qualifierList[0]}
                          options={qualifierList}
                          value={
                            qualifierList.find(
                              c => c === selectedQualifier || ''
                            ) || null
                          }
                          renderInput={params => (
                            <TextField
                              sx={{width: 150}}
                              label="Qualifier"
                              {...params}
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Stack justifyContent="flex-end">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={300}
                  >
                    <Switch
                      defaultChecked
                      onChange={event =>
                        setIsDisplayDolarDeed(event.target.checked)
                      }
                      color="primary"
                    />
                    <Typography>Display dollar deed</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            isDisplayCard &&
            loading && (
              <>
                <Stack spacing={3} direction="row" alignItems="center">
                  <Typography ml={2} variant="overline">
                    STATE CAPITAL
                  </Typography>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <CircularProgress color="primary" size={20} />
                    <Typography variant="caption" color="primary">
                      Searching in State Capital
                    </Typography>
                  </Stack>
                </Stack>
                <Stack ml={2} mt={2}>
                  <Stack direction="row" spacing={2}>
                    <Skeleton width={90} height={30} />
                    <Skeleton width={90} height={30} />
                    <Skeleton width={90} height={30} />
                  </Stack>
                </Stack>
              </>
            )
          )}
          <CardContent>
            {selectedBuilding && isDisplayCard ? (
              <Card sx={{backgroundColor: 'grey.200'}}>
                <CardContent sx={{pt: 2}}>
                  <Stack
                    flexGrow={1.8}
                    direction={isLaptop ? 'column' : 'row'}
                    spacing={4}
                    alignItems={isLaptop ? 'left' : 'center'}
                  >
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Box
                        sx={{
                          borderRadius: 1,
                          padding: 0.8,
                          backgroundColor: 'primary'
                        }}
                      >
                        <HomeIcon color="primary" />
                      </Box>
                      <Typography variant="h6">
                        {selectedBuilding.properties[index].address +
                          ', ' +
                          selectedBuilding.municipality +
                          ', NJ'}
                      </Typography>
                    </Stack>
                    <Stack
                      flexGrow={1}
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <CuiTitleContent
                        title="COUNTY"
                        content={
                          selectedBuilding.county.replace(' County', '') || ''
                        }
                      />
                      <CuiTitleContent
                        title="MUNICIPALITY"
                        content={selectedBuilding.municipality || ''}
                      />
                      <CuiTitleContent
                        title="BLOCK"
                        content={selectedBuilding.block || ''}
                      />
                      <CuiTitleContent
                        title="LOT"
                        content={selectedBuilding.lot || ''}
                      />
                      {selectedBuilding.properties[index].qualifier !== '' && (
                        <CuiTitleContent
                          title="QALIFIER"
                          content={
                            selectedBuilding.properties[index].qualifier || ''
                          }
                        />
                      )}
                      <CuiTitleContent
                        title="OWNER"
                        content={selectedBuilding.properties[index].owner || ''}
                        isTextAlignLeft
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ) : (
              isDisplayCard && loading && <Skeleton height={150} />
            )}
            {displayList && assessmentsList && assessmentsList.length > 0 && (
              <>
                <Card sx={{marginTop: 3}}>
                  <CardContent
                    sx={{
                      overflowX: 'auto',
                      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: 'white',
                        height: 6
                      },
                      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                        {
                          borderRadius: '12px',
                          backgroundColor: 'grey.500',
                          paddingBottom: '6px'
                        }
                    }}
                  >
                    <TimeLine assessmentsList={displayList} />
                  </CardContent>
                </Card>
                <Stack sx={{mt: 5}}>
                  <Table
                    assessmentsList={displayList}
                    setShowAlert={setShowAlert}
                    link={
                      (selectedBuilding &&
                        Object.entries(CountyLinks).find(
                          ([key]) =>
                            key === selectedBuilding.county.toUpperCase()
                        )?.[1]) ||
                      ''
                    }
                  />
                  {showAlert && (
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      open={showAlert}
                      onClose={() => setShowAlert(false)}
                      message="Copied to clipboard !"
                      autoHideDuration={6000}
                      sx={{
                        '& .css-1q7r04h-MuiPaper-root-MuiSnackbarContent-root':
                          {
                            minWidth: 0
                          }
                      }}
                    />
                  )}
                </Stack>
              </>
            )}
            {assessmentsList && assessmentsList.length === 0 && !loading && (
              <Card sx={{px: 4, mt: 2}}>
                <CardContent>
                  <Typography
                    sx={{textAlign: 'center'}}
                    color="gery.600"
                    variant="body2"
                  >
                    No property details found
                  </Typography>
                </CardContent>
              </Card>
            )}
            {assessmentsList === null && isDisplayCard && (
              <>
                <Card sx={{px: 4, mt: 1}}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                    <Skeleton sx={{flex: 20}} height={3} />
                    <Skeleton sx={{flex: 30}} height={120} />
                  </Stack>
                </Card>
                <Stack ml={2} mr={2} mt={4} spacing={3}>
                  <Stack spacing={10} direction="row">
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                  </Stack>
                  <Stack spacing={10} direction="row">
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                    <Skeleton sx={{flex: 1}} height={25} />
                  </Stack>
                </Stack>
              </>
            )}
            {!loading && buildings.length === 0 && (
              <Stack paddingX={12} alignItems="center">
                <Typography color="gery.600" variant="body2">
                  No property found
                </Typography>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}
    </>
  )
}
