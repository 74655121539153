import FilterField from 'src/entities/search/backtitle/FilterField'
import {Order, OrderType} from 'src/entities/search/general/Order'
import {getUtcDateFromLocale} from 'src/utils/formatDate'

const filterInternalSearchData = (
  data: Order[],
  filterFields: FilterField[]
) =>
  data.filter((o: Order) =>
    filterFields.every(
      f =>
        typeof o[f.name as keyof Order] === 'string' &&
        (o[f.name as keyof Order] as string)
          .split(/,| |&|and/)
          .some((t: string) => t.toUpperCase() === f.value.toUpperCase())
    )
  )

export const GetSelectData = (data: Order[], filterFields?: FilterField[]) => {
  const filtered = filterFields ? filterInternalSearchData(data, filterFields) : data
  filtered.forEach((order: Order) => {
    order.buyers = [
      {
        firstName: Object.entries(order).find(([k]) => k === 'buyer')?.[1],
        lastName: ''
      }
    ]
    order.sellers = [
      {
        firstName: Object.entries(order).find(([k]) => k === 'seller')?.[1],
        lastName: ''
      }
    ]
    order.type = OrderType.Select
  })
  return filtered
}

export const GetTitleWaveData = (data: Order[], filterFields?: FilterField[]) => {
  const filtered = filterFields ? filterInternalSearchData(data, filterFields) : data
  filtered.forEach((order: Order) => {
    Object.entries(order)
      .filter(([k]) => k.startsWith('seller') || k.startsWith('buyer'))
      .forEach(([k, v]) => {
        if (!!v) {
          const key = k.startsWith('seller') ? 'sellers' : 'buyers'
          const personNumber = Number(k.slice(-1)) - 1
          if (!!!order[key]) {
            order[key] = []
          }
          if (!!!order[key][personNumber]) {
            order[key][personNumber] = {
              firstName: '',
              lastName: ''
            }
          }
          order[key][personNumber][
            k.includes('First') ? 'firstName' : 'lastName'
          ] = v
        }
      })
    order.openDate = getUtcDateFromLocale(order.openDate)
    order.closeDate = order.closeDate && getUtcDateFromLocale(order.closeDate)
    order.type = OrderType.TitleWave
  })
  return filtered
}
