import {Dispatch, MouseEvent, SetStateAction} from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import BacktitleSearch from 'src/components/search/backtitle/BacktitleSearch'
import GeneralSearch from 'src/components/search/general/GeneralSearch'
import Page from 'src/components/Page'
import {Building} from 'src/entities/search/backtitle/stateCapital/Building'
import {SearchType} from 'src/entities/search/SearchType'
import {Order} from 'src/entities/search/general/Order'
import {Link} from 'react-router-dom'

interface SearchCardProps {
  onChangeType: (
    event: MouseEvent<HTMLElement>,
    searchType: SearchType | null
  ) => void
  selectedSearchType: SearchType
  setBuildings: (value: Building[]) => void
  setOrders: Dispatch<SetStateAction<Order[]>>
  setLoading: (value: boolean) => void
  setLoadingIS: (value: boolean) => void
  setIsSearch: (value: boolean) => void
  setGeneralTextSearch: (value: string) => void
  loading: boolean
  setLoadingGeneral: (value: boolean) => void
}

export default function SearchCard({
  onChangeType,
  selectedSearchType,
  setBuildings,
  setOrders,
  setLoading,
  setLoadingIS,
  setIsSearch,
  setGeneralTextSearch,
  setLoadingGeneral,
  loading
}: SearchCardProps) {
  return (
    <Page title={`${SearchType[Number(selectedSearchType)]} Search`}>
      <Card>
        <CardHeader
          title={
            <ToggleButtonGroup
              exclusive
              value={selectedSearchType}
              onChange={onChangeType}
              color="primary"
            >
              {Object.keys(SearchType)
                .filter(key => isNaN(Number(SearchType[key as any])))
                .map(type => {
                  let value = Number(type)
                  let label = SearchType[Number(type)]
                  return (
                    <ToggleButton
                      size="small"
                      key={value}
                      value={value}
                      aria-label={`${label.toLowerCase()} search`}
                      component={Link}
                      to={`/search/${label.toLowerCase()}`}
                    >
                      {label}
                    </ToggleButton>
                  )
                })}
            </ToggleButtonGroup>
          }
        />
        <CardContent sx={{py: 1}}>
          {selectedSearchType === SearchType.General ? (
            <GeneralSearch
              setGeneralTextSearch={setGeneralTextSearch}
              setOrders={setOrders}
              setLoadingGeneral={setLoadingGeneral}
            />
          ) : (
            <BacktitleSearch
              setBuildings={setBuildings}
              setOrders={setOrders}
              setLoading={setLoading}
              setLoadingIS={setLoadingIS}
              setIsSearch={setIsSearch}
              loading={loading}
            />
          )}
        </CardContent>
      </Card>
    </Page>
  )
}
