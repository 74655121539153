import {MouseEvent} from 'react'
import {useDropzone} from 'react-dropzone'
import {styled} from '@mui/material/styles'
import {Box, IconButton, Stack, Tooltip} from '@mui/material'
import {UploadProps} from 'src/components/upload_file/type'
import BlockContent from 'src/components/upload_file/BlockContent'
import LaunchIcon from '@mui/icons-material/Launch'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

const DropZoneStyle = styled('div')(({theme}) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {opacity: 0.72, cursor: 'pointer'}
}))

export default function UploadSingleFile({
  file,
  sx,
  setFile,
  onDelete,
  loading,
  ...other
}: UploadProps) {
  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone(
    {
      multiple: false,
      accept: {
        'application/pdf': ['.pdf']
      },
      ...other
    }
  )

  const onDeleteFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setFile(null)
    onDelete()
  }
  const onPreview = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    file && window.open(typeof file === 'string' ? file : file.preview)
  }

  return (
    <Box sx={{width: '100%', ...sx}}>
      <DropZoneStyle
        {...getRootProps({
          onClick: event => loading && event.stopPropagation()
        })}
        sx={{
          ...(isDragActive && {opacity: 0.72}),
          ...(isDragReject && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file
            ? {
                py: 1.625
              }
            : {
                py: {sm: 0.25, xl: 1}
              })
        }}
      >
        <input disabled={loading} {...getInputProps()} />

        {!file ? (
          <BlockContent />
        ) : (
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{xs: 'column', md: 'row'}}
            sx={{width: 1, textAlign: {xs: 'center', md: 'left'}}}
          >
            <IconButton
              sx={{
                borderRadius: 1,
                width: 140,
                height: 100,
                backgroundColor: 'white'
              }}
            >
              <PictureAsPdfIcon fontSize="large" color="error" />
            </IconButton>
            <Box sx={{p: 3}}>
              <Stack direction="row" spacing={3}>
                <IconButton disabled={loading} onClick={e => onPreview(e)}>
                  <Tooltip placement="bottom" title={'Open in new tab'}>
                    <LaunchIcon sx={{color: 'grey.800'}} />
                  </Tooltip>
                </IconButton>
                <IconButton disabled={loading} onClick={e => onDeleteFile(e)}>
                  <Tooltip placement="bottom" title={'Delete'}>
                    <DeleteOutlineIcon sx={{color: 'grey.800'}} />
                  </Tooltip>
                </IconButton>
                <IconButton disabled={loading}>
                  <Tooltip placement="bottom" title={'Upload new file'}>
                    <FileUploadOutlinedIcon sx={{color: 'grey.800'}} />
                  </Tooltip>
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        )}
      </DropZoneStyle>
    </Box>
  )
}
