import React from 'react'
import {useLocation, Navigate} from 'react-router-dom'
import {Box, Typography} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import LoginButton from 'src/components/auth/LoginButton'

export default function LoginPage() {
  const location = useLocation()
  const {isAuth} = useAuth()

  const {from} = (location.state as any) || {from: {pathname: '/'}}

  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Navigate replace to={from} />

  return (
    <Box
      textAlign="center"
      position="static"
      sx={{
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Typography component="h1" variant="h2">
        Welcome to OTS
      </Typography>
      <LoginButton />
    </Box>
  )
}
