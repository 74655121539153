import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {Autocomplete, Button, Stack, TextField, Typography} from '@mui/material'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import County from 'src/entities/search/backtitle/County'
import Municipality from 'src/entities/search/backtitle/Municipality'
import {Building} from 'src/entities/search/backtitle/stateCapital/Building'
import {addDays} from 'date-fns'
import {Order} from 'src/entities/search/general/Order'
import FilterField from 'src/entities/search/backtitle/FilterField'
import {useSearchParams} from 'react-router-dom'
import {RHFUploadSingleFile} from 'src/components/upload_file/RHFUpload'
import {getPDFtext, readFileAsync} from 'src/utils/getTextPDF'
import CuiCopyContent from 'src/components/custom/CuiCopyContent'
import {
  extractTitleWaveSearchRequest,
  extractMadisonSearchRequest
} from 'src/components/search/backtitle/ExtractParams'
import {GetSelectData, GetTitleWaveData} from 'src/utils/getOrders'

type SearchValuesProps = {
  block: string
  lot: string
  qualifier: string
  countyId: number
  municipalityId?: number
  address: string
  owner: string
}

interface ValidateSearchFields {
  isValid: Boolean
  isCountyValid: Boolean
  isBlockValid: Boolean
  isLotValid: Boolean
}
interface BacktitleSearchProps {
  setBuildings: (value: Building[]) => void
  setOrders: Dispatch<SetStateAction<Order[]>>
  setLoading: (value: boolean) => void
  setLoadingIS: (value: boolean) => void
  setIsSearch: (value: boolean) => void
  loading: boolean
}

export default function BacktitleSearch({
  setBuildings,
  setOrders,
  setLoading,
  setLoadingIS,
  setIsSearch,
  loading
}: BacktitleSearchProps) {
  const [counties, setCounties] = useState<County[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])
  const defaultSearchValues: SearchValuesProps = useMemo(
    () => ({
      block: '',
      lot: '',
      qualifier: '',
      countyId: 0,
      municipalityId: 0,
      address: '',
      owner: ''
    }),
    []
  )
  const [searchValues, setSearchValues] =
    useState<SearchValuesProps>(defaultSearchValues)
  const [fieldsValidation, setFieldsValidation] =
    useState<ValidateSearchFields>({
      isValid: false,
      isCountyValid: false,
      isBlockValid: false,
      isLotValid: false
    })
  const [autoSearch, setAutoSearch] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [advancedDetails, setAdvancedDetails] = useState<{
    address: string | null
    owner: string | null
  }>({
    address: null,
    owner: null
  })
  const {fetchWithUser} = useAuth()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const counties = JSON.parse(localStorage.getItem('counties') || '{}')
    if (
      !counties ||
      !counties.value ||
      new Date().getTime() > counties.expiry
    ) {
      fetchWithUser(config.apiUrl + `/Counties`)
        .then(res => res.json())
        .then((data: County[]) => {
          localStorage.setItem(
            'counties',
            JSON.stringify({
              value: data,
              expiry: addDays(new Date(), 1).getTime()
            })
          )
          setCounties(data)
        })
        .catch(err => console.log(err))
    } else {
      setCounties(counties.value)
    }
  }, [fetchWithUser])

  useEffect(() => {
    if (Array.from(searchParams).length && counties.length) {
      const county = counties.find(
        c => c.name.toLowerCase() === searchParams.get('county')?.toLowerCase()
      )
      setSearchValues({
        block: searchParams.get('block') || '',
        lot: searchParams.get('lot') || '',
        qualifier: searchParams.get('qualifier') || '',
        countyId: county?.id || 0,
        municipalityId:
          county?.municipalities?.find(
            m =>
              m.name.toLowerCase() ===
              searchParams.get('municipality')?.toLowerCase()
          )?.id || 0,
        address: searchParams.get('address') || '',
        owner: searchParams.get('owner') || ''
      })
      setMunicipalities(county?.municipalities || [])
      setAutoSearch(true)
    }
  }, [searchParams, counties])

  const onClearAll = useCallback(() => {
    setSearchValues(defaultSearchValues)
    setFieldsValidation({
      isValid: false,
      isCountyValid: false,
      isBlockValid: false,
      isLotValid: false
    })
    setAdvancedDetails({address: null, owner: null})
    setMunicipalities([])
    setFile(null)
  }, [defaultSearchValues])

  const editValidationOnSave = useCallback(() => {
    const isValidFields =
      !searchValues.address && !searchValues.block && !searchValues.owner
    const isValidQualifierField =
      !!searchValues.qualifier && (!searchValues.block || !searchValues.lot)
    setFieldsValidation({
      isValid: isValidQualifierField ? false : isValidFields,
      isCountyValid: !searchValues.countyId,
      isBlockValid: !!searchValues.qualifier && !searchValues.block,
      isLotValid: !!searchValues.qualifier && !searchValues.lot
    })
    return !(!searchValues.countyId || isValidQualifierField || isValidFields)
  }, [
    searchValues.address,
    searchValues.block,
    searchValues.countyId,
    searchValues.lot,
    searchValues.owner,
    searchValues.qualifier
  ])

  const editValidationOnChange = useCallback(() => {
    const isValidFields =
      (fieldsValidation.isValid &&
        !searchValues.qualifier &&
        !searchValues.address &&
        !searchValues.block &&
        !searchValues.owner) ||
      (!searchValues.qualifier &&
        (fieldsValidation.isBlockValid || fieldsValidation.isLotValid))
    const isQualifierField =
      !!searchValues.qualifier &&
      (fieldsValidation.isBlockValid || fieldsValidation.isLotValid)

    setFieldsValidation({
      isValid: isValidFields,
      isCountyValid: fieldsValidation.isCountyValid && !searchValues.countyId,
      isBlockValid: !!isQualifierField && !searchValues.block,
      isLotValid: !!isQualifierField && !searchValues.lot
    })
  }, [
    searchValues.address,
    searchValues.block,
    searchValues.countyId,
    searchValues.lot,
    searchValues.owner,
    searchValues.qualifier,
    fieldsValidation.isCountyValid,
    fieldsValidation.isValid,
    fieldsValidation.isBlockValid,
    fieldsValidation.isLotValid
  ])

  const onTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValues(prev => {
      var newValues = {
        ...prev,
        [e.target.name]: e.target.value
      }
      return newValues
    })
  }

  useEffect(() => {
    editValidationOnChange()
  }, [searchValues, editValidationOnChange])

  const onChangeCounty = (county: County | null) => {
    setSearchValues(prev => ({
      ...prev,
      countyId: Number(county?.id || null),
      municipalityId: 0
    }))
    setMunicipalities(county?.municipalities || [])
  }

  const onChangeMunicipality = (municipality: Municipality | null) => {
    setSearchValues(prev => ({
      ...prev,
      municipalityId: Number(municipality?.id)
    }))
  }

  const trimValues = (searchValues: SearchValuesProps) =>
    Object.fromEntries(
      Object.entries(searchValues).map(([k, v]) => [
        k,
        typeof v === 'string' ? v.trim() : v
      ])
    )

  const stateCapitalSearch = useCallback(() => {
    const options = {
      method: 'POST',
      body: JSON.stringify(trimValues(searchValues)),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    ;(async () => {
      setLoading(true)
      await fetchWithUser(config.apiUrl + `/Property/Search`, options)
        .then(res => res.json())
        .then(data => {
          setBuildings(data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    })()
  }, [fetchWithUser, searchValues, setBuildings, setLoading])

  const searchInSelect = useCallback(
    async (filterFields: FilterField[]) => {
      await fetchWithUser(`${config.apiUrl}/Search/internalsearch`, {
        method: 'POST',
        body: JSON.stringify({
          query: '',
          filterFields: filterFields
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then((data: Order[]) => {
          setOrders(prev => prev.concat(GetSelectData(data, filterFields)))
        })
    },
    [fetchWithUser, setOrders]
  )

  const searchInTitleWave = useCallback(
    async (filterFields: FilterField[]) => {
      await fetchWithUser(`${config.apiUrl}/Search/search`, {
        method: 'POST',
        body: JSON.stringify({
          query: '',
          filterFields: filterFields
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then((data: Order[]) => {
          setOrders(prev => prev.concat(GetTitleWaveData(data, filterFields)))
        })
    },
    [fetchWithUser, setOrders]
  )

  const internalSearch = useCallback(() => {
    setLoadingIS(true)
    const filterFields: FilterField[] = []
    searchValues.block !== '' &&
      filterFields.push({
        name: 'block',
        value: searchValues.block,
        isPerfix: true
      } as FilterField)
    searchValues.lot !== '' &&
      filterFields.push({
        name: 'lot',
        value: searchValues.lot,
        isPerfix: true
      } as FilterField)
    searchValues.address !== '' &&
      filterFields.push({
        name: 'address',
        value: searchValues.address,
        isPerfix: true
      } as FilterField)
    searchValues.countyId !== 0 &&
      filterFields.push({
        name: 'county',
        value: counties.find(c => c.id === searchValues.countyId)?.name,
        isPerfix: true
      } as FilterField)
    searchValues.municipalityId !== 0 &&
      filterFields.push({
        name: 'city',
        value: municipalities.find(m => m.id === searchValues.municipalityId)
          ?.name,
        isPerfix: true
      } as FilterField)

    return new Promise(() => {
      Promise.all([
        searchInSelect(filterFields),
        searchInTitleWave(filterFields)
      ]).then(() => {
        setLoadingIS(false)
      })
    })
  }, [
    counties,
    municipalities,
    searchInTitleWave,
    searchInSelect,
    setLoadingIS,
    searchValues.address,
    searchValues.block,
    searchValues.countyId,
    searchValues.lot,
    searchValues.municipalityId
  ])

  const onSearchProperties = useCallback(() => {
    if (editValidationOnSave()) {
      setIsSearch(true)
      setOrders([])
      setBuildings([])
      stateCapitalSearch()
      internalSearch()
    }
  }, [
    editValidationOnSave,
    internalSearch,
    setOrders,
    setBuildings,
    stateCapitalSearch,
    setIsSearch
  ])

  useEffect(() => {
    if (autoSearch) {
      onSearchProperties()
      setAutoSearch(false)
    }
  }, [autoSearch, onSearchProperties])

  useEffect(() => {
    if (file) {
      readFileAsync(file).then((data: any) => {
        getPDFtext(data)
          .then((content: any) => {
            let county: string,
              block: string,
              lot: string,
              qualifier: string,
              fullAddress: string,
              owner: string
            //TitleWave
            if (content.some((c: string) => c.includes('TitleProbe'))) {
              ;[county, block, lot, qualifier, fullAddress, owner] =
                extractTitleWaveSearchRequest(content[0])
            }
            //Madison
            else {
              ;[county, block, lot, qualifier, fullAddress, owner] =
                extractMadisonSearchRequest(content[0])
            }
            const selectedCounty = counties.find(
              c => c.name.toLowerCase() === county?.toLowerCase()
            )
            setSearchValues({
              block: block,
              lot: lot,
              qualifier: qualifier,
              countyId: selectedCounty?.id || 0,
              municipalityId: 0,
              // address: fullAddress.split(' ').slice(0, 2).join(' '),
              address: '',
              owner: ''
            })
            setMunicipalities(selectedCounty?.municipalities || [])
            setAdvancedDetails({
              address: fullAddress,
              owner: owner
            })
            setAutoSearch(true)
          })
          .catch(() => {
            console.log('Invalid file, name: ', file.name)
            onClearAll()
          })
      })
    }
  }, [file, counties, onClearAll])

  return (
    <>
      <Stack width="100%" direction="row" spacing={3} alignItems="flex-start">
        <Stack flex={3} width={'100%'}>
          <RHFUploadSingleFile
            setFile={setFile}
            file={file}
            onDelete={onClearAll}
            loading={loading}
          />
        </Stack>
        <Stack flex={7.5} width={'100%'}>
          <Stack
            spacing={2}
            direction={{xs: 'column', md: 'row'}}
            alignItems="flex-start"
          >
            <Stack flex={5} width={'100%'} spacing={2}>
              <Stack flex={5} width={'100%'} direction="row" spacing={2}>
                <TextField
                  disabled={loading}
                  fullWidth
                  label="Block"
                  value={searchValues.block}
                  name="block"
                  onChange={onTextFieldChange}
                  error={
                    !!fieldsValidation.isValid ||
                    !!fieldsValidation.isBlockValid
                  }
                />
                <TextField
                  disabled={loading}
                  fullWidth
                  label="Lot"
                  value={searchValues.lot}
                  name="lot"
                  onChange={onTextFieldChange}
                  error={!!fieldsValidation.isLotValid}
                />
                <TextField
                  disabled={loading}
                  fullWidth
                  label="Qualifier"
                  value={searchValues.qualifier}
                  onChange={onTextFieldChange}
                  name="qualifier"
                />
              </Stack>
              <TextField
                disabled={loading}
                fullWidth
                label="Address"
                value={searchValues.address}
                name="address"
                onChange={onTextFieldChange}
                error={!!fieldsValidation.isValid}
                helperText={
                  advancedDetails.address && (
                    <>
                      {advancedDetails.address}
                      <CuiCopyContent
                        title="Copy full address as it appears in the search request"
                        content={advancedDetails.address}
                      />
                    </>
                  )
                }
              />
            </Stack>
            <Stack flex={5} width={'100%'} spacing={2}>
              <Stack flex={5} width={'100%'} direction="row" spacing={2}>
                <Autocomplete
                  disabled={loading}
                  fullWidth
                  onChange={(event, newValue: County | null) => {
                    onChangeCounty(newValue)
                  }}
                  options={counties}
                  value={
                    counties.find(c => c.id === searchValues?.countyId || '') ||
                    null
                  }
                  renderInput={params => (
                    <TextField
                      required
                      label="County"
                      error={!!fieldsValidation.isCountyValid}
                      {...params}
                    />
                  )}
                  getOptionLabel={county => county.name}
                />
                <Autocomplete
                  disabled={loading}
                  fullWidth
                  onChange={(event, newValue: Municipality | null) => {
                    onChangeMunicipality(newValue)
                  }}
                  options={municipalities}
                  value={
                    municipalities.find(
                      m => m.id === searchValues?.municipalityId || ''
                    ) || null
                  }
                  renderInput={params => (
                    <TextField label="Municipality" {...params} />
                  )}
                  getOptionLabel={municipality => municipality.name}
                />
              </Stack>
              <TextField
                disabled={loading}
                fullWidth
                label="Owner"
                value={searchValues.owner}
                name="owner"
                onChange={onTextFieldChange}
                error={!!fieldsValidation.isValid}
                helperText={
                  advancedDetails.owner && (
                    <>
                      {advancedDetails.owner}
                      <CuiCopyContent
                        title="Copy owner name as it appears in the search request"
                        content={advancedDetails.owner}
                      />
                    </>
                  )
                }
              />
            </Stack>
          </Stack>
          <Stack justifyItems="left">
            {fieldsValidation.isValid && (
              <Typography color={'error'} sx={{pt: 1}}>
                At least one of Block/Address/Owner fields is required
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        direction={{xs: 'column', md: 'row'}}
        sx={{pt: 2}}
        justifyContent="space-between"
      >
        <Button disabled={loading} size="large" onClick={onClearAll}>
          Clear all
        </Button>
        <Button
          disabled={loading}
          size="large"
          variant="contained"
          sx={{alignSelf: 'flex-end'}}
          onClick={onSearchProperties}
        >
          Search
        </Button>
      </Stack>
    </>
  )
}
