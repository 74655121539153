import {Navigate, useRoutes} from 'react-router-dom'
import LoginPage from 'src/components/auth/LoginPage'
import LogoutPage from 'src/components/auth/LogoutPage'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import SearchPage from 'src/components/search/SearchPage'
import {SearchType} from 'src/entities/search/SearchType'
import DashboardLayout from 'src/layouts/dashboard'

export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/logout',
      element: <LogoutPage />
    },
    {
      path: '/',
      element: <Navigate to="/search/general" replace />
    },
    {
      path: '/search',
      element: <DashboardLayout />,
      children: [
        {element: <Navigate to="/search/general" replace />, index: true},
        {
          path: 'general',
          element: (
            <ProtectedRoute>
              <SearchPage searchType={SearchType.General} />
            </ProtectedRoute>
          )
        },
        {
          path: 'backtitle',
          element: (
            <ProtectedRoute>
              <SearchPage searchType={SearchType.Backtitle} />
            </ProtectedRoute>
          )
        },
        {
          path: 'recent',
          element: <div>page2</div>
        }
      ]
    }
  ])
}
