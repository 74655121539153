import {Box, Link, Stack, Typography} from '@mui/material'
import {Assessment} from 'src/entities/search/backtitle/stateCapital/Assessment'
import {formatDate} from 'src/utils/formatTime'
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import LaunchIcon from '@mui/icons-material/Launch'
import CuiCopyContent from 'src/components/custom/CuiCopyContent'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'

interface TableProps {
  assessmentsList: Assessment[]
  setShowAlert: (value: boolean) => void
  link: string
}

const columns = (setShowAlert: (value: boolean) => void, link: string) => [
  {
    field: 'saleDate',
    headerName: 'Sale Date',
    flex: 0.8,
    sortable: false,
    valueFormatter: (cell: GridValueFormatterParams) =>
      cell.value ? formatDate(cell.value) : '---'
  },
  {
    field: 'salePrice',
    headerName: 'Sale Price',
    sortable: false,
    flex: 0.8
  },
  {
    field: 'deedBook',
    headerName: 'Book',
    sortable: false,
    flex: 0.4
  },
  {
    field: 'CopydBook',
    headerName: '',
    flex: 0.4,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      return (
        params.row.deedBook && (
          <CuiCopyContent
            title="Copy"
            content={params.row.deedBook}
            onClick={() => setShowAlert(true)}
          />
        )
      )
    }
  },
  {
    field: 'deedPage',
    headerName: 'Page',
    sortable: false,
    flex: 0.4
  },
  {
    field: 'CopydPage',
    headerName: '',
    flex: 0.4,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      return (
        params.row.deedPage && (
          <CuiCopyContent
            title="Copy"
            content={params.row.deedPage}
            onClick={() => setShowAlert(true)}
          />
        )
      )
    }
  },
  {
    field: 'ownerName',
    headerName: 'Owner',
    flex: 1.6,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <CuiOverflowTooltip value={params.row.ownerName} />
    )
  },
  {
    field: 'countyLink',
    headerName: '',
    flex: 0.6,
    sortable: false,
    renderCell: () =>
      link ? (
        <Link href={link} target="_blank" underline="none">
          <Stack
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            direction="row"
            spacing={1}
          >
            <LaunchIcon sx={{color: 'grey.800'}} fontSize="small" />
            <Typography color="grey.800" variant="button">
              County
            </Typography>
          </Stack>
        </Link>
      ) : (
        <></>
      )
  }
]

export default function Table({
  assessmentsList,
  setShowAlert,
  link
}: TableProps) {
  const rows = [...assessmentsList]

  return (
    <Box sx={{width: '100%'}}>
      <DataGrid
        autoHeight
        rows={rows.reverse()}
        columns={columns(setShowAlert, link)}
        checkboxSelection={false}
        hideFooter={true}
        getRowId={(row: Assessment) => row.year}
        sx={{
          '& .MuiCheckbox-root.Mui-disabled': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none'
          },
          '& .MuiDataGrid-cell': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeader:focus-within, .css-up4904-MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none'
            },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none'
          }
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  )
}
