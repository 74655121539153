// @mui
import {Stack, Button, Typography} from '@mui/material'
// assets
import {FeedbackIllustration} from '../../../assets'

// ----------------------------------------------------------------------

export default function NavbarFeedback() {
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block'
      }}
    >
      <FeedbackIllustration sx={{width: 1}} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Your feedback matters!
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Take a few minutes to tell us about your experience
        </Typography>
      </div>
      <Button variant="contained">Give feedback</Button>
    </Stack>
  )
}
