import React from 'react'
import {Card, Skeleton, Stack} from '@mui/material'
import {Order} from 'src/entities/search/general/Order'
import Table from 'src/components/search/results/backtitle/internalSearch/Table'

interface GeneralSearchResultsProps {
  orders: Order[]
  generalTextSearch: string
  loadingGeneral: boolean
}

export default function GeneralSearchResults({
  orders,
  generalTextSearch,
  loadingGeneral
}: GeneralSearchResultsProps) {
  return (
    <>
      {!loadingGeneral && orders.length > 0 && (
        <Card sx={{mt: 3, p: 2}}>
          <Table
            generalTextSearch={generalTextSearch}
            orders={orders}
            isGeneral={true}
          />
        </Card>
      )}
      {loadingGeneral && (
        <Stack spacing={2}>
          <Skeleton height={100} />
          <Stack spacing={6} px={2}>
            <Stack direction="row" spacing={4}>
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.7}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.8}} height={25} />
            </Stack>
            <Stack direction="row" spacing={4}>
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.7}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.8}} height={25} />
            </Stack>
            <Stack direction="row" spacing={4}>
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.7}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.8}} height={25} />
            </Stack>
            <Stack direction="row" spacing={4}>
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.7}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.15}} height={25} />
              <Skeleton sx={{flex: 0.4}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.25}} height={25} />
              <Skeleton sx={{flex: 0.8}} height={25} />
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}
