import {useState} from 'react'
// @mui
import {alpha} from '@mui/material/styles'
import {Box, Divider, Typography, MenuItem, Avatar} from '@mui/material'
// components
import MenuPopover from '../../../components/MenuPopover'
import {IconButtonAnimate} from '../../../components/animate'
import {useAuth} from 'src/contexts/Auth'

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null)

  const {user, logout} = useAuth()

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const onLogout = () => {
    logout()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <Avatar>
          {user?.givenName?.charAt(0)}
          {user?.surname?.charAt(0)}
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {user?.mail}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}} />

        <MenuItem sx={{m: 1}} onClick={onLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
