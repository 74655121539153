import {Person} from 'src/entities/search/general/Person'

export enum OrderType {
  TitleWave,
  Select
}

export interface Order {
  rootId: string
  orderNumber: string
  block: string
  lot: string
  address: string
  city: string
  state: string
  county: string
  underwriter: string
  transactionType: string
  salesPrice: string
  openDate: Date
  closeDate?: Date
  companyName: string
  streetName: string
  streetNumber: string
  unit: string
  buyers: Person[]
  sellers: Person[]
  type: OrderType
}
