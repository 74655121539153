import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.js'

export function getPDFtext(content: any) {
  return new Promise((resolve, reject) => {
    pdfjsLib.getDocument(content).promise.then(
      (pdf: any) => {
        var pagesPromises = []
        for (var i = 0; i < pdf.numPages; i++) {
          // Required to prevent that i is always the total of pages
          ;(pageNumber => {
            pagesPromises.push(getPageText(pageNumber, pdf))
          })(i + 1)
        }
        Promise.all(pagesPromises).then(pagesText => {
          resolve(pagesText)
        })
      },
      (reason: any) => {
        reject(reason)
      }
    )
  })
}

/**
 * Retrieves the text of a specif page within a PDF Document obtained through pdf.js
 *
 * @param {Integer} pageNum Specifies the number of the page
 * @param {PDFDocument} PDFDocumentInstance The PDF document obtained
 **/
export function getPageText(pageNum: number, PDFDocumentInstance: any) {
  // Return a Promise that is solved once the text of the page is retrieven
  return new Promise(resolve => {
    PDFDocumentInstance.getPage(pageNum).then((pdfPage: any) => {
      // The main trick to obtain the text of the PDF page, use the getTextContent method
      pdfPage.getTextContent().then((textContent: any) => {
        var finalString = ''
        // Concatenate the string of the item to the final string
        for (var i = 0; i < textContent.items.length; i++) {
          finalString += textContent.items[i].str + ' '
        }
        resolve(finalString)
      })
    })
  })
}

export function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}
