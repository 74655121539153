import React from 'react'
import {
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import {Order} from 'src/entities/search/general/Order'
import Table from 'src/components/search/results/backtitle/internalSearch/Table'

interface InternalSearchResultsProps {
  loadingIS: boolean
  orders: Order[]
  isSearch: boolean
}

export default function InternalSearchResults({
  orders,
  loadingIS,
  isSearch
}: InternalSearchResultsProps) {
  return (
    <>
      {!loadingIS && isSearch ? (
        <Card sx={{mt: 3, p: 2}}>
          <Stack spacing={1}>
            <Typography ml={2} variant="overline">
              INTERNAL SEARCH ({orders.length})
            </Typography>
            {orders.length > 0 ? (
              <Table orders={orders} />
            ) : (
              <Stack paddingX={12} alignItems="center">
                <Typography color="gery.600" variant="body2">
                  No results found
                </Typography>
                <Typography color="gery.600" variant="body2">
                  {' '}
                  Check for typos or try more generic search
                </Typography>
              </Stack>
            )}{' '}
          </Stack>
        </Card>
      ) : (
        loadingIS &&
        isSearch && (
          <Card sx={{mt: 3, p: 2}}>
            <Stack spacing={3} direction="row" alignItems="center">
              <Typography ml={2} variant="overline">
                INTERNAL SEARCH
              </Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <CircularProgress color="primary" size={20} />
                <Typography variant="caption" color="primary">
                  Searching files
                </Typography>
              </Stack>
            </Stack>
            <CardContent>
              <Stack spacing={3}>
                <Skeleton height={100} />
                <Stack
                  sx={{pl: 2, pr: 2}}
                  marginTop={3}
                  direction="row"
                  spacing={2}
                >
                  <Skeleton sx={{flex: 0.5}} height={30} />
                  <Skeleton sx={{flex: 1}} height={30} />
                  <Skeleton sx={{flex: 0.5}} height={30} />
                  <Skeleton sx={{flex: 0.5}} height={30} />
                  <Skeleton sx={{flex: 0.3}} height={30} />
                  <Skeleton sx={{flex: 1}} height={30} />
                  <Skeleton sx={{flex: 1}} height={30} />
                  <Skeleton sx={{flex: 4}} height={30} />
                  <Skeleton sx={{flex: 0.5}} height={30} />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )
      )}
    </>
  )
}
