export function extractTitleWaveSearchRequest(content: string) {
  let county = content.split('County:')[1].trim().split(' ')[0].split(',')[0]
  let block = content.split('Block:')[1].trim().split(' ')[0]
  let str = content.split('Lot:')[1].trim().split('Block')[0].split(';')
  let lot = str[0]
  let qualifier =
    str.length > 1
      ? str[1].includes(':')
        ? str[1].split(': ')[1].trim()
        : str[1].trim()
      : ''
  let fullAddress = content.split('Address:')[1].trim().split('Condo')[0].trim()
  let owner = content.split('Seller Information')[1].split('COMMENTS')[0].trim()
  return [county, block, lot, qualifier, fullAddress, owner] as const
}

export function extractMadisonSearchRequest(content: string) {
  let county =
    content.split('County:').length > 1
      ? content.split('County:')[1].trim().split(' ')[0]
      : content.split('County of ')[1].trim().split(' ')[0].split(',')[0]
  let block = content.split('Block ')[1].trim().split(' ')[0]
  let str = content.split('Lot')[1].startsWith('/')
    ? content.split('Lot')[2].split(',')[0].trim().includes('(')
      ? content.split('Lot')[2].split(',')[0].trim().split('(')
      : content.split('Lot')[2].split(',')[0].trim().split(' ')
    : content.split('Lot')[1].startsWith('s')
    ? content.split('Lot')[1].split('s ')[1].split(',')[0].split(' ')
    : content.split('Lot')[1].trim().split(',')[0].split(' ')

  let lot = str[0].trim()
  let qualifier =
    str.length > 1
      ? str[1].includes('&')
        ? ''
        : str[1].split(')')[0].trim()
      : ''
  let fullAddress = content
    .split('Premises:')[1]
    .trim()
    .split('County')[0]
    .trim()
  let owner = content.split('Owner(s):')[1].trim().split('Buyer')[0].trim()
  return [county, block, lot, qualifier, fullAddress, owner] as const
}
