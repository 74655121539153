// components
import SvgIconStyle from '../../../components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{width: 1, height: 1}}
  />
)

const ICONS = {
  search: getIcon('ic_search'),
  invoice: getIcon('ic_invoice')
}

const navConfig = [
  {
    subheader: '',
    items: [
      {title: 'Search', path: '/search/general', icon: ICONS.search}
      // {title: 'Recent Search', path: '/search/recent', icon: ICONS.invoice}
    ]
  }
]

export default navConfig
