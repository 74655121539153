import React from 'react'
import {
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import {Assessment} from 'src/entities/search/backtitle/stateCapital/Assessment'
import {formatDate, getFullYear} from 'src/utils/formatTime'

interface TimeLineProps {
  assessmentsList: Assessment[]
}

export default function TimeLine({assessmentsList}: TimeLineProps) {
  const getYear = (props: StepIconProps) => {
    return (
      <Typography variant="subtitle2" color="primary">
        {assessmentsList[Number(props.icon) - 1].year
          ? getFullYear(assessmentsList[Number(props.icon) - 1].saleDate)
          : 'missing'}
      </Typography>
    )
  }

  return (
    <Stepper
      sx={{
        '.css-t2zj5t-MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: 0
        }
      }}
      alternativeLabel
    >
      {assessmentsList.map(label => (
        <Step key={label.year}>
          <StepLabel sx={{minWidth: 150}} StepIconComponent={getYear}>
            <Stack>
              <Typography variant="caption">{label.salePrice}</Typography>
              <Typography
                variant="caption"
                color={theme => theme.palette.grey[500]}
              >
                {label.saleDate ? formatDate(label.saleDate) : '---'}
              </Typography>
            </Stack>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
