import {Dispatch, SetStateAction, useCallback} from 'react'
import {InputAdornment, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import config from 'src/config'
import {Order} from 'src/entities/search/general/Order'
import {useAuth} from 'src/contexts/Auth'
import {GetTitleWaveData} from 'src/utils/getOrders'
import {useDebouncedCallback} from 'use-debounce'

interface GeneralSearchProps {
  setOrders: Dispatch<SetStateAction<Order[]>>
  setGeneralTextSearch: (value: string) => void
  setLoadingGeneral: (value: boolean) => void
}

export default function GeneralSearch({
  setOrders,
  setGeneralTextSearch,
  setLoadingGeneral
}: GeneralSearchProps) {
  const {fetchWithUser} = useAuth()

  const searchInTitleWave = useCallback(
    async (query: string) => {
      setLoadingGeneral(true)
      await fetchWithUser(`${config.apiUrl}/Search/search`, {
        method: 'POST',
        body: JSON.stringify({
          query: query,
          filterFields: null
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then((data: Order[]) => {
          setOrders(prev => prev.concat(GetTitleWaveData(data)))
        })
        .then(() => {
          setLoadingGeneral(false)
        })
    },
    [fetchWithUser, setOrders, setLoadingGeneral]
  )

  const debounced = useDebouncedCallback(debouncedText => {
    setOrders([])
    if (debouncedText && debouncedText !== '') {
      setGeneralTextSearch(debouncedText)
      searchInTitleWave(debouncedText)
    }
  }, 300)

  return (
    <TextField
      fullWidth
      placeholder="Search..."
      onChange={e => debounced(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}
