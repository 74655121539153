import {MouseEvent, useEffect, useState} from 'react'
import {Container, Stack, Typography} from '@mui/material'
import Page from 'src/components/Page'
import SearchCard from 'src/components/search/SearchCard'
import StateCapitalResults from 'src/components/search/results/backtitle/stateCapital/StateCapitalResults'
import {Building} from 'src/entities/search/backtitle/stateCapital/Building'
import {SearchType} from 'src/entities/search/SearchType'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import UploadIllustration from 'src/assets/illustration_upload'
import {Assessment} from 'src/entities/search/backtitle/stateCapital/Assessment'
import {Order} from 'src/entities/search/general/Order'
import InternalSearchResults from 'src/components/search/results/backtitle/internalSearch/InternalSearchResults'
import GeneralSearchResults from 'src/components/search/results/general/GeneralSearchResults'

export default function SearchPage({searchType}: {searchType: SearchType}) {
  const [selectedSearchType, setSelectedSearchType] =
    useState<SearchType>(searchType)
  const [buildings, setBuildings] = useState<Building[]>([])
  const [assessments, setAssessments] = useState<Map<string, Assessment[]>>(
    new Map()
  )
  const [orders, setOrders] = useState<Order[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingIS, setLoadingIS] = useState<boolean>(false)
  const [loadingGeneral, setLoadingGeneral] = useState<boolean>(false)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [generalTextSearch, setGeneralTextSearch] = useState<string>('')

  const {fetchWithUser} = useAuth()

  const handleChangeType = (
    _event: MouseEvent<HTMLElement>,
    searchType: SearchType | null
  ) => {
    if (searchType !== null) {
      setSelectedSearchType(searchType)
    }
  }

  useEffect(() => {
    setSelectedSearchType(searchType)
    setBuildings([])
    setOrders([])
    setAssessments(new Map())
    setIsSearch(false)
    setLoading(false)
  }, [searchType])

  useEffect(() => {
    buildings.forEach((building, key) => {
      building.properties.forEach(async (property, index) => {
        if (key || index) {
          await fetchWithUser(
            config.apiUrl +
              `/Property/GetHistory?recordKey=` +
              property.recordKey
          )
            .then(res => res.json())
            .then(data => {
              setAssessments(prev => new Map(prev.set(key + '_' + index, data)))
            })
        } else {
          setAssessments(
            prev =>
              new Map(prev.set(key + '_' + index, property.assessmentHistory))
          )
        }
      })
    })
  }, [buildings, fetchWithUser])

  return (
    <Page title="Search">
      <Container maxWidth={false}>
        <SearchCard
          onChangeType={handleChangeType}
          selectedSearchType={selectedSearchType}
          setBuildings={setBuildings}
          setOrders={setOrders}
          setLoading={setLoading}
          setLoadingIS={setLoadingIS}
          setIsSearch={setIsSearch}
          setGeneralTextSearch={setGeneralTextSearch}
          loading={loading}
          setLoadingGeneral={setLoadingGeneral}
        />
        {selectedSearchType === SearchType.General && (
          <GeneralSearchResults
            generalTextSearch={generalTextSearch}
            orders={orders}
            loadingGeneral={loadingGeneral}
          />
        )}
        {selectedSearchType === SearchType.Backtitle &&
        isSearch &&
        orders.length === 0 &&
        buildings.length === 0 &&
        !loading ? (
          <Stack sx={{p: 15}} alignItems="center">
            <UploadIllustration sx={{width: 140}} />
            <Typography color="grey.600" variant="body2">
              No results found
            </Typography>
            <Typography color="grey.600" variant="body2">
              Check for typos or try more generic search
            </Typography>
          </Stack>
        ) : (
          <Stack>
            <InternalSearchResults
              orders={orders}
              loadingIS={loadingIS}
              isSearch={isSearch}
            />
            <StateCapitalResults
              buildings={buildings}
              assessments={assessments}
              loading={loading}
              isSearch={isSearch}
            />
          </Stack>
        )}
      </Container>
    </Page>
  )
}
