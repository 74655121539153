import React from 'react'
import {IconButton, Tooltip} from '@mui/material'
import {ContentCopyRounded} from '@mui/icons-material'

interface CuiCopyContentProps {
  title?: string
  content: string
  onClick?: () => void
}

export default function CuiCopyContent({
  title,
  content,
  onClick
}: CuiCopyContentProps) {
  return (
    <Tooltip placement="bottom" title={title || ''}>
      <IconButton
        size="small"
        onClick={() => {
          onClick && onClick()
          return navigator.clipboard.writeText(content || '')
        }}
      >
        <ContentCopyRounded sx={{color: 'grey.800'}} fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
