import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useAuth} from 'src/contexts/Auth'

export default function ProtectedRoute({children}: any) {
  const {isAuth, isMsalAuth} = useAuth()
  const location = useLocation()

  if (isAuth) {
    return children
  }

  if (isMsalAuth) {
    return <div>Loading...</div>
  }

  return <Navigate to="/login" state={{from: location.pathname}} />
}
