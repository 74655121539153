import {ReactNode} from 'react'
import {Stack, Typography} from '@mui/material'

interface CuiTitleContentProps {
  title: string
  content: ReactNode
  isTextAlignLeft?: boolean
}

export default function CuiTitleContent({
  title,
  content,
  isTextAlignLeft
}: CuiTitleContentProps) {
  return (
    <Stack
      spacing={1}
      flexGrow={0.5}
      textAlign={isTextAlignLeft ? 'left' : 'center'}
    >
      <Typography variant="overline" color={theme => theme.palette.grey[500]}>
        {title}
      </Typography>
      {content}
    </Stack>
  )
}
