import {UploadProps} from 'src/components/upload_file/type'
import UploadSingleFile from 'src/components/upload_file/UploadSingleFile'

interface Props extends Omit<UploadProps, 'file'> {
  file: File | null
  setFile: (fiels: File | null) => void
  onDelete: () => void
  loading: boolean
}

export function RHFUploadSingleFile({file, setFile, loading, ...other}: Props) {
  return (
    <UploadSingleFile
      loading={loading}
      setFile={setFile}
      onDrop={acceptedFile => {
        setFile(
          acceptedFile[0]
            ? Object.assign(acceptedFile[0], {
                preview: URL.createObjectURL(acceptedFile[0])
              })
            : null
        )
      }}
      file={file}
      {...other}
    />
  )
}
