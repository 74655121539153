import React, {useRef, useEffect, useState, ReactNode} from 'react'
import {Stack, Tooltip, Typography} from '@mui/material'

interface CuiOverflowTooltipProps {
  value: ReactNode
}
export default function CuiOverflowTooltip({value}: CuiOverflowTooltipProps) {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setIsOverflow(compare)
    }
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize)
    },
    []
  )

  return value ? (
    <Tooltip
      title={<Stack>{value}</Stack>}
      disableHoverListener={!isOverflowed}
      arrow
    >
      <Typography
        variant={'body2'}
        gutterBottom
        ref={textElementRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  ) : (
    <div>{value}</div>
  )
}
